import { useCallback, useEffect, useState } from "react";
import {
  ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_APPROVED_BY_SET,
  ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_CHOICES,
  ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_ERROR,
  ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_SET_UNKNOWN_ERROR,
  ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_UPLOADED_TO_SET,
  ElectronicDocumentDisablement,
} from "../../models/electronic-document-disablement";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import { getRoutePath } from "../../routes";
import DisablementUpdateModal from "./DisablementModal";
import DisablementDeleteModal from "./DisablementDeleteModal";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CPopover,
  CRow,
  CTooltip,
} from "@coreui/react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  DISABLEMENTS_CREATE,
  DISABLEMENTS_DESTROY,
  DISABLEMENTS_UPDATE,
} from "../../auth/permissions";

const ITEMS_PER_PAGE = 10;

const DisablementCrud = () => {
  const fields = [
    { key: "stampIdentifier", _classes: ["text-center"], label: "Timbrado Nº" },
    {
      key: "establishmentCode",
      _classes: ["text-center"],
      label: "Establecimiento",
    },
    {
      key: "dispatchPointIdentifier",
      _classes: ["text-center"],
      label: "Punto de expedición",
    },
    {
      key: "documentType",
      _classes: ["text-center"],
      label: "Tipo de Documento",
    },
    {
      key: "from",
      _classes: ["text-center"],
      label: "Desde",
    },
    {
      key: "to",
      _classes: ["text-center"],
      label: "Hasta",
    },
    {
      key: "reason",
      _classes: ["text-center"],
      label: "Motivo",
    },
    {
      key: "status",
      _classes: ["text-center"],
      label: "Estado",
    },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [item, setItem] = useState<ElectronicDocumentDisablement | undefined>(
    undefined
  );

  const [items, setItems] = useState<ElectronicDocumentDisablement[]>([]);

  const [loading, setLoading] = useState(true);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }

    return { page: page };
  };

  const fetchItems = useCallback(async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.page - 1);
    const additionalParams = new Map<string, string>();
    additionalParams.set("tax_payer_id", taxPayer.id!.toString());
    const itemsStatus = await getList<ElectronicDocumentDisablement>(
      "/disablements/",
      limit,
      offset,
      additionalParams
    );

    if (itemsStatus.status === SUCCESS) {
      if (itemsStatus.data !== undefined) {
        const count = itemsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setItems(itemsStatus.data.items);
        setCurrentPage(urlParams.page);
      }
    } else {
      const message = itemsStatus.detail
        ? itemsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  /*const reset = () => {
    history.push(history.location.pathname);
  };*/

  const onAddClick = () => {
    setItem(undefined);
    setShowEditModal(true);
  };

  const onUpdateClick = (item: ElectronicDocumentDisablement) => {
    setItem(item);
    setShowEditModal(true);
  };

  const onDeleteClick = (establishment: ElectronicDocumentDisablement) => {
    setItem(establishment);
    setShowDeleteModal(true);
  };

  const onEditCancel = () => {
    setShowEditModal(false);
  };

  const onEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowEditModal(false);
    setItems([]);
    setLoading(true);
    //setTotalPages(1);
    //setCurrentPage(1);
    //reset();
    fetchItems();
  };

  const onDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowDeleteModal(false);
    setItems([]);
    setLoading(true);
    fetchItems();
  };

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("disablements")) {
        return;
      }
      fetchItems();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    setLoading(true);
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Inutilizaciones</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent
                  permissionName={DISABLEMENTS_CREATE}
                >
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={items}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    status: (item: ElectronicDocumentDisablement) => {
                      let badgeClassName = "badge-info";

                      if (
                        item.status ===
                        ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_UPLOADED_TO_SET
                      ) {
                        badgeClassName = "badge-warning";
                      }

                      if (
                        item.status ===
                        ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_ERROR
                      ) {
                        badgeClassName = "badge-danger";
                      }

                      if (
                        item.status ===
                        ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_APPROVED_BY_SET
                      ) {
                        badgeClassName = "badge-success";
                      }

                      return (
                        <td className="text-center">
                          <span
                            className={`badge badge-pill ${badgeClassName}`}
                            style={{ fontSize: "14px" }}
                          >
                            {ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_CHOICES.get(
                              item.status!
                            )}{" "}
                            {item.status ===
                            ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_ERROR ? (
                              <CPopover
                                content={
                                  <ul
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                      paddingBottom: "0px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    <li>Código: {item.setResponseCode}</li>
                                    <li>
                                      Descripción: {item.setResponseDescription}
                                    </li>
                                  </ul>
                                }
                                placement="top"
                                trigger="click"
                              >
                                <span
                                  className="fa fa-info-circle"
                                  style={{ cursor: "pointer" }}
                                ></span>
                              </CPopover>
                            ) : (
                              <></>
                            )}
                            {item.status ===
                            ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_SET_UNKNOWN_ERROR ? (
                              <CPopover
                                content={
                                  <ul
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                      paddingBottom: "0px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    <li>Error: {item.setError}</li>
                                  </ul>
                                }
                                placement="top"
                                trigger="click"
                              >
                                <span
                                  className="fa fa-info-circle"
                                  style={{ cursor: "pointer" }}
                                ></span>
                              </CPopover>
                            ) : (
                              <></>
                            )}
                          </span>
                        </td>
                      );
                    },
                    actions: (item: ElectronicDocumentDisablement) => {
                      const editButton = (
                        <PermissionRequiredComponent
                          permissionName={DISABLEMENTS_UPDATE}
                        >
                          <CTooltip content="Editar">
                            <CButton
                              className="text-white"
                              color="warning"
                              onClick={() => {
                                onUpdateClick(item);
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );

                      const deleteButton = (
                        <PermissionRequiredComponent
                          permissionName={DISABLEMENTS_DESTROY}
                        >
                          <CTooltip content="Eliminar">
                            <CButton
                              className="text-white"
                              color="danger"
                              onClick={() => {
                                onDeleteClick(item);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </CButton>
                          </CTooltip>
                        </PermissionRequiredComponent>
                      );

                      return (
                        <td className="text-center">
                          <CButtonGroup>
                            {editButton}
                            {deleteButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <DisablementUpdateModal
        show={showEditModal}
        onCancel={onEditCancel}
        onSuccess={onEditSuccess}
        disablement={item}
      />
      <DisablementDeleteModal
        show={showDeleteModal}
        onCancel={onDeleteCancel}
        onSuccess={onDeleteSuccess}
        disablement={item}
      />
    </>
  );
};

export default DisablementCrud;
