import React from "react";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import "font-awesome/css/font-awesome.min.css";

import "./index.css";
import App from "./App";
import AuthProvider from "./providers/AuthProvider";
import DashboardProvider from "./providers/DashboardProvider";
import store from "./store";
import { icons } from "./assets/icons/index";
import EventsProvider from "./providers/EventsProvider";
import PermissionsProvider from "./providers/PermissionsProvider";

// @ts-ignore
React.icons = icons;

const getApp = () => {
  let app = (
    <Provider store={store}>
      <DashboardProvider>
        <AuthProvider>
          <PermissionsProvider>
            <EventsProvider>
              <App />
            </EventsProvider>
          </PermissionsProvider>
        </AuthProvider>
      </DashboardProvider>
    </Provider>
  );

  return app;
};

ReactDOM.render(getApp(), document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
