import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, successAlert, warningAlert } from "../utils/messages";
import { getItem, getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CInput,
  CInputGroup,
  CInputGroupText,
  CLabel,
  CPagination,
  CRow,
  CSelect,
  CTooltip,
} from "@coreui/react";

import moment from "moment";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import { BsFillPlusCircleFill } from "react-icons/bs";
import { CURRENCY_CHOICES } from "../../currency/available-currencies";
import { formatToCurrency } from "../../currency/format";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { formatUTCDateTime } from "../../utils/dates";
import { getObjectMinioUrl } from "../../minio/urls";
import {
  RECEIPT_STATUS_CHOICES,
  Receipt,
  formatReceiptNumber,
} from "../../models/receipt";
import ReceiptDeleteModal from "./ReceiptDeleteModal";
import ReceiptCrudActions from "./ReceiptCrudActions";
import ReceiptCrudStatus from "./ReceiptCrudStatus";
import ReceiptCancellationModal from "./ReceiptCancellationModal";
import {
  SET_ENVIRONMENT_CHOICES,
  SET_ENVIRONMENT_DEVELOPMENT,
  SET_ENVIRONMENT_PRODUCTION,
} from "../../models/set-environment";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import { RECEIPTS_CREATE, RECEIPTS_RETRIEVE } from "../../auth/permissions";

const ITEMS_PER_PAGE = 10;

const ReceiptCrud = () => {
  const fields = [
    { key: "receiptDate", _classes: ["text-center"], label: "Fecha" },
    { key: "stampIdentifier", _classes: ["text-center"], label: "Timbrado" },
    { key: "receiptNumber", _classes: ["text-center"], label: "Nro." },
    { key: "clientRuc", _classes: ["text-center"], label: "RUC" },
    {
      key: "clientSocialName",
      _classes: ["text-center"],
      label: "Razón Social",
    },
    { key: "currency", _classes: ["text-center"], label: "Moneda" },
    { key: "amount", _classes: ["text-center"], label: "Monto" },
    { key: "status", _classes: ["text-center"], label: "Estado" },
    { key: "files", _classes: ["text-center"], label: "Archivos" },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  // Filters
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [setEnvironment, setSetEnvironment] = useState<string | undefined>(
    undefined
  );
  const [status, setStatus] = useState<string | undefined>(undefined);

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<Receipt[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  // Item control
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingItem, setDeletingItem] = useState<Receipt | undefined>(
    undefined
  );

  // Cancel control
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [cancelling, setCancelling] = useState<Receipt | undefined>(undefined);

  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }

    let search = urlParams.get("search") ? urlParams.get("search") : "";

    let startDateStr = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : null;

    let startDate: Date | null = null;
    if (startDateStr !== null && startDateStr !== "") {
      startDate = new Date(startDateStr);
    }

    let endDateStr = urlParams.get("end_date")
      ? urlParams.get("end_date")
      : null;

    let endDate: Date | null = null;
    if (endDateStr !== null && endDateStr !== "") {
      endDate = new Date(endDateStr);
    }

    let setEnvironmentStr = urlParams.get("set_environment");

    let setEnvironment: string | undefined;
    if (setEnvironmentStr !== null) {
      setEnvironment = setEnvironmentStr;
    }

    let statusStr = urlParams.get("status");

    let status: string | undefined;
    if (statusStr !== null) {
      status = statusStr;
    }

    return {
      page: page,
      search: search,
      startDate: startDate,
      endDate: endDate,
      setEnvironment: setEnvironment,
      status: status,
    };
  };

  const fetchItems = useCallback(async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.page - 1);
    const additionalParams = new Map<string, string>();
    additionalParams.set("tax_payer_id", taxPayer.id!.toString());

    if (urlParams.search !== null && urlParams.search !== "") {
      additionalParams.set("search", urlParams.search);
    }

    if (urlParams.startDate !== null) {
      additionalParams.set("start_date", urlParams.startDate.toISOString());
    }

    if (urlParams.endDate !== null) {
      additionalParams.set("end_date", urlParams.endDate.toISOString());
    }

    if (urlParams.setEnvironment !== undefined) {
      additionalParams.set("set_environment", urlParams.setEnvironment);
    }

    if (urlParams.status !== undefined) {
      additionalParams.set("status", urlParams.status);
    }

    setSearch(urlParams.search ? urlParams.search : "");
    setStartDate(urlParams.startDate);
    setEndDate(urlParams.endDate);
    setSetEnvironment(urlParams.setEnvironment);
    setStatus(urlParams.status);

    const requestState = await getList<Receipt>(
      "/receipts/",
      limit,
      offset,
      additionalParams
    );

    if (requestState.status === SUCCESS) {
      if (requestState.data !== undefined) {
        const count = requestState.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setItems(requestState.data.items);
        setCurrentPage(urlParams.page);
      }
    } else {
      const message = requestState.detail
        ? requestState.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    history.push(getRoutePath("addReceipt"));
  };

  const onUpdateClick = (receipt: Receipt) => {
    const baseUrl = getRoutePath("addReceipt");
    history.push(`${baseUrl}?id=${receipt.id!}`);
  };

  const onDeleteClick = (receipt: Receipt) => {
    setDeletingItem(receipt);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("receipts")) {
        return;
      }
      fetchItems();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    setLoading(true);
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  const onDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowDeleteModal(false);
    setDeletingItem(undefined);
    setLoading(true);
    fetchItems();
  };

  const onCancellationClick = (receipt: Receipt) => {
    setCancelling(receipt);
    setShowCancellationModal(true);
  };

  const onCancellationCancel = () => {
    setShowCancellationModal(false);
  };

  const onCancellationSuccess = () => {
    successAlert("Registro anulado con éxito!");
    setShowCancellationModal(false);
    setCancelling(undefined);
    setLoading(true);
    fetchItems();
  };

  const onPDFClick = async (receipt: Receipt) => {
    const pdfState = await getItem<{ url: string }>(
      `/receipts/${receipt.id}/pdf/`
    );

    if (pdfState.status !== SUCCESS || pdfState.data === undefined) {
      errorAlert("Error al tratar de obtener el PDF");
      return;
    }
    window.open(getObjectMinioUrl(pdfState.data.url), "_blank");
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onStartDateChange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }

    const date = new Date((value as moment.Moment).toISOString());

    setStartDate(date);
  };

  const onEndDateChange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }

    const date = new Date((value as moment.Moment).toISOString());

    setEndDate(date);
  };

  const onSetEnvironmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSetEnvironment = e.target.value;

    if (
      newSetEnvironment === SET_ENVIRONMENT_DEVELOPMENT ||
      newSetEnvironment === SET_ENVIRONMENT_PRODUCTION
    ) {
      setSetEnvironment(newSetEnvironment);
    } else {
      setSetEnvironment(undefined);
    }
  };

  const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStatus = e.target.value;

    if (RECEIPT_STATUS_CHOICES.has(newStatus)) {
      setStatus(newStatus);
    } else {
      setStatus(undefined);
    }
  };

  const onSearch = useCallback(() => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);

    if (search !== undefined) {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }

    if (startDate !== null) {
      urlParams.set("start_date", startDate.toISOString());
    } else {
      urlParams.delete("start_date");
    }

    if (endDate !== null) {
      urlParams.set("end_date", endDate.toISOString());
    } else {
      urlParams.delete("end_date");
    }

    if (setEnvironment !== undefined) {
      urlParams.set("set_environment", setEnvironment);
    } else {
      urlParams.delete("set_environment");
    }

    if (status !== undefined) {
      urlParams.set("status", status);
    } else {
      urlParams.delete("status");
    }

    let url = `?${urlParams.toString()}`;
    history.push(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, startDate, endDate, setEnvironment, status]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const onDownloadKudesClick = useCallback(async () => {
    let additionalParams = new Map<string, string>();

    additionalParams.set("tax_payer_id", taxPayer.id!.toString());

    if (search !== undefined && search !== "") {
      additionalParams.set("search", search);
    }

    if (startDate !== null) {
      additionalParams.set("start_date", startDate.toISOString());
    }
    if (endDate !== null) {
      additionalParams.set("end_date", endDate.toISOString());
    }

    if (setEnvironment !== undefined && setEnvironment !== "") {
      additionalParams.set("set_environment", setEnvironment);
    }

    if (status !== undefined && status !== "") {
      additionalParams.set("status", status);
    }

    const pdfState = await getItem<{ url: string }>(
      "/receipts/kudes/",
      additionalParams
    );

    if (pdfState.status !== SUCCESS || pdfState.data === undefined) {
      errorAlert("Error al tratar de obtener el PDF");
      return;
    }
    window.open(getObjectMinioUrl(pdfState.data.url), "_blank");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer, search, startDate, endDate, setEnvironment, status]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Recibos</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent permissionName={RECEIPTS_CREATE}>
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDateChange}
                    value={startDate !== null ? startDate : ""}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                    utc
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDateChange}
                    value={endDate !== null ? endDate : ""}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                    utc
                  ></Datetime>
                </CCol>
              </CRow>

              <CRow className="mb-2 mt-2">
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Ambiente SET:</CLabel>
                </CCol>
                <CCol md={4}>
                  <CSelect
                    type="text"
                    value={setEnvironment}
                    onChange={onSetEnvironmentChange}
                  >
                    <option value={""}>-----</option>
                    {Array.from(SET_ENVIRONMENT_CHOICES.entries()).map(
                      (entry) => {
                        return (
                          <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Estado:</CLabel>
                </CCol>
                <CCol md={4}>
                  <CSelect
                    type="text"
                    value={setEnvironment}
                    onChange={onStatusChange}
                  >
                    <option value={""}>-----</option>
                    {Array.from(RECEIPT_STATUS_CHOICES.entries()).map(
                      (entry) => {
                        return (
                          <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                </CCol>
              </CRow>

              <CRow className="mb-4">
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de búsqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Filtrar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>

              <CRow className="mb-2">
                <CCol>
                  <CButton
                    color="danger"
                    className="fa fa-file"
                    onClick={onDownloadKudesClick}
                  ></CButton>
                </CCol>
              </CRow>

              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={items}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    receiptDate: (item: Receipt) => {
                      return (
                        <td className="text-center">
                          {formatUTCDateTime(new Date(item.receiptDate!))}
                        </td>
                      );
                    },
                    receiptNumber: (item: Receipt) => {
                      if (item.receiptNumber === undefined) {
                        return <td className="text-center">-</td>;
                      }
                      return (
                        <td className="text-center">
                          {formatReceiptNumber(item)}
                        </td>
                      );
                    },
                    currency: (item: Receipt) => {
                      return (
                        <td className="text-center">
                          {CURRENCY_CHOICES.get(item.currency!)}
                        </td>
                      );
                    },
                    amount: (item: Receipt) => {
                      return (
                        <td className="text-center">
                          {formatToCurrency(item.amount!, item.currency!)}
                        </td>
                      );
                    },
                    status: (item: Receipt) => (
                      <ReceiptCrudStatus receipt={item} />
                    ),
                    files: (item: Receipt) => {
                      let pdfButton = null;

                      if (item.pdfPath !== undefined) {
                        pdfButton = (
                          <PermissionRequiredComponent
                            permissionName={RECEIPTS_RETRIEVE}
                          >
                            <CTooltip content="PDF">
                              <CButton
                                className="text-white"
                                color="danger"
                                onClick={() => {
                                  onPDFClick(item);
                                }}
                              >
                                <i className="fa fa-file"></i>
                              </CButton>
                            </CTooltip>
                          </PermissionRequiredComponent>
                        );
                      }

                      return (
                        <td className="text-center">
                          <CButtonGroup>{pdfButton}</CButtonGroup>
                        </td>
                      );
                    },
                    actions: (item: Receipt) => (
                      <ReceiptCrudActions
                        receipt={item}
                        onCancellationClick={onCancellationClick}
                        onUpdateClick={onUpdateClick}
                        onDeleteClick={onDeleteClick}
                      />
                    ),
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <ReceiptDeleteModal
        show={showDeleteModal}
        onCancel={onDeleteCancel}
        onSuccess={onDeleteSuccess}
        receipt={deletingItem}
      />
      <ReceiptCancellationModal
        show={showCancellationModal}
        onCancel={onCancellationCancel}
        onSuccess={onCancellationSuccess}
        receipt={cancelling}
      />
    </>
  );
};

export default ReceiptCrud;
