import { CButton, CButtonGroup, CTooltip } from "@coreui/react";
import {
  CREDIT_NOTE_STATUS_APPROVED_BY_SET,
  CREDIT_NOTE_STATUS_ERROR,
  CREDIT_NOTE_STATUS_PENDING,
  CREDIT_NOTE_STATUS_SET_UNKNOWN_ERROR,
  CREDIT_NOTE_STATUS_UPLOADED_TO_SET,
  CreditNote,
} from "../../models/credit-note";
import { useEffect, useState } from "react";
import { useSub } from "../../hooks/pub-sub";
import {
  CREDIT_NOTES_DESTROY,
  CREDIT_NOTES_UPDATE,
} from "../../auth/permissions";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";

interface CreditNoteCrudAtionsProps {
  creditNote: CreditNote;
  onCancellationClick: (_: CreditNote) => void;
  onUpdateClick: (_: CreditNote) => void;
  onDeleteClick: (_: CreditNote) => void;
  onConsultByCdcClick: (_: CreditNote) => void;
  onResendClick: (_: CreditNote) => void;
  onSendEmailClick: (_: CreditNote) => void;
}

const CreditNoteCrudActions: React.FC<CreditNoteCrudAtionsProps> = ({
  creditNote,
  onCancellationClick,
  onUpdateClick,
  onDeleteClick,
  onConsultByCdcClick,
  onResendClick,
  onSendEmailClick,
}) => {
  const [currentCreditNote, setCurrentCreditNote] = useState(creditNote);

  useEffect(() => {
    setCurrentCreditNote(creditNote);
  }, [creditNote]);

  useSub(`credit_note_${creditNote.id}`, (creditNote: CreditNote) => {
    setCurrentCreditNote(creditNote);
  });
  const cancelButton = (
    <PermissionRequiredComponent permissionName={CREDIT_NOTES_UPDATE}>
      <CTooltip content="Anular">
        <CButton
          className="text-white"
          color="secondary"
          onClick={() => {
            onCancellationClick(creditNote);
          }}
        >
          <i className="fa fa-ban"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const editButton = (
    <PermissionRequiredComponent permissionName={CREDIT_NOTES_UPDATE}>
      <CTooltip content="Editar">
        <CButton
          className="text-white"
          color="warning"
          onClick={() => {
            onUpdateClick(creditNote);
          }}
        >
          <i className="fa fa-pencil"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const deleteButton = (
    <PermissionRequiredComponent permissionName={CREDIT_NOTES_DESTROY}>
      <CTooltip content="Eliminar">
        <CButton
          className="text-white"
          color="danger"
          onClick={() => {
            onDeleteClick(creditNote);
          }}
        >
          <i className="fa fa-trash"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const documentDateTime = new Date(
    currentCreditNote.creditNoteDate!
  ).getTime();
  const now = new Date().getTime();

  const olderThan7Days = documentDateTime < now - 7 * 24 * 60 * 60 * 1000;

  const shouldShowCancelButton =
    currentCreditNote.status === CREDIT_NOTE_STATUS_APPROVED_BY_SET &&
    !olderThan7Days;

  const shouldShowEditAndDeleteButton =
    currentCreditNote.status === CREDIT_NOTE_STATUS_PENDING ||
    currentCreditNote.status === CREDIT_NOTE_STATUS_ERROR ||
    currentCreditNote.status === CREDIT_NOTE_STATUS_SET_UNKNOWN_ERROR;

  const consultByCdcButton = (
    <PermissionRequiredComponent permissionName={CREDIT_NOTES_UPDATE}>
      <CTooltip content="Consultar por CDC">
        <CButton
          className="text-white"
          color="primary"
          onClick={() => {
            onConsultByCdcClick(creditNote);
          }}
        >
          <i className="fa fa-question-circle"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const shouldShowConsultByCdcButton =
    currentCreditNote.status === CREDIT_NOTE_STATUS_UPLOADED_TO_SET;

  const resendButton = (
    <PermissionRequiredComponent permissionName={CREDIT_NOTES_UPDATE}>
      <CTooltip content="Reenviar">
        <CButton
          className="text-white"
          color="success"
          onClick={() => {
            onResendClick(creditNote);
          }}
        >
          <i className="fa fa-refresh"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const shouldShowResendButton =
    currentCreditNote.status === CREDIT_NOTE_STATUS_ERROR ||
    currentCreditNote.status === CREDIT_NOTE_STATUS_UPLOADED_TO_SET;

  const sendEmailButton = (
    <PermissionRequiredComponent permissionName={CREDIT_NOTES_UPDATE}>
      <CTooltip content="Enviar Correo">
        <CButton
          className="text-white"
          color="info"
          onClick={() => {
            onSendEmailClick(creditNote);
          }}
        >
          <i className="fa fa-envelope"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const shouldShowSendEmailButton =
    currentCreditNote.status === CREDIT_NOTE_STATUS_APPROVED_BY_SET &&
    currentCreditNote.emailSent === false;

  return (
    <td className="text-center">
      <CButtonGroup>
        {shouldShowCancelButton ? cancelButton : <></>}
        {shouldShowEditAndDeleteButton ? (
          <>
            {editButton}
            {deleteButton}
          </>
        ) : (
          <></>
        )}
        {shouldShowConsultByCdcButton ? consultByCdcButton : <></>}
        {shouldShowResendButton ? resendButton : <></>}
        {shouldShowSendEmailButton ? sendEmailButton : <></>}
      </CButtonGroup>
    </td>
  );
};

export default CreditNoteCrudActions;
